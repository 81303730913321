export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET';

export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL';
export const PASSWORD_CHANGE_RESET = 'PASSWORD_CHANGE_RESET';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAIL = 'ACCOUNT_DELETE_FAIL';
export const ACCOUNT_DELETE_RESET = 'ACCOUNT_DELETE_RESET';

// CATEGORY CONSTANTS
export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';
export const CATEGORY_LIST_RESET = 'CATEGORY_LIST_RESET';

// PRODUCT CONSTANTS
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const GET_PRODUCT_RESET = 'GET_PRODUCT_RESET';

export const TAGS_PRODUCT = 'TAGS_PRODUCT';
export const TAGS_PRODUCT_FAIL = 'TAGS_PRODUCT_FAIL';
export const TAGS_PRODUCT_RESET = 'TAGS_PRODUCT_RESET';

// CART CONSTANTS
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_RESET = 'CART_RESET';

// ORDER CONSTANTS
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_GET_ALL_REQUEST = 'ORDER_GET_ALL_REQUEST';
export const ORDER_GET_ALL_SUCCESS = 'ORDER_GET_ALL_SUCCESS';
export const ORDER_GET_ALL_FAIL = 'ORDER_GET_ALL_FAIL';
export const ORDER_GET_ALL_RESET = 'ORDER_GET_ALL_RESET';

export const ORDER_GET_BY_ID_REQUEST = 'ORDER_GET_BY_ID_REQUEST';
export const ORDER_GET_BY_ID_SUCCESS = 'ORDER_GET_BY_ID_SUCCESS';
export const ORDER_GET_BY_ID_FAIL = 'ORDER_GET_BY_ID_FAIL';
export const ORDER_GET_BY_ID_RESET = 'ORDER_GET_BY_ID_RESET';

export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET';

export const ORDER_DELETE_ALL_REQUEST = 'ORDER_DELETE_ALL_REQUEST';
export const ORDER_DELETE_ALL_SUCCESS = 'ORDER_DELETE_ALL_SUCCESS';
export const ORDER_DELETE_ALL_FAIL = 'ORDER_DELETE_ALL_FAIL';
export const ORDER_DELETE_ALL_RESET = 'ORDER_DELETE_ALL_RESET';

export const STRIPE = 'STRIPE';
export const STRIPE_ERROR = 'STRIPE_ERROR';
export const STRIPE_RESET = 'STRIPE_RESET';
